:root{
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif;
  --font-size: 16px;
}

body{
  margin:0;
  cursor: default;
  user-select: none;
  font-family: var(--font-family);
  font-size: var(--font-size);

  background: #000;
  color:#fff;
}

*{
  position: relative;
  box-sizing: border-box;
}
